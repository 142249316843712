import * as React from "react"
import { graphql, Link } from "gatsby";
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import bomontiPenthouse from '../../../images/bomonti-penthouse.png'
import floryaPenthouse from '../../../images/florya-penthouse.png'
import kosuyoluPenthouse from '../../../images/kosuyolu-penthouse.png'
import kosuyoluPenthouse2 from '../../../images/kosuyolu-penthouse-2.png'
import atasehirDaire from '../../../images/atasehir-daire.png'
import burhaniyeKonaklari from '../../../images/burhaniye-konaklari.png'
import arrowThin from '../../../images/projects-arrow-thin.svg'
import backToProjects from '../../../images/back-to-projects.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Penthouse = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <Seo title={t("fPenthouse")} />
            <div className="mt-40 relative flex items-center justify-center md:mt-28">
                <div className="max-w-full overflow-hidden wrapper">
                    <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">{t("PenthouseDaire")}</p>
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">{t("PenthouseDaire")}</p>
                    </div>
                </div>
                <h1 className="mb-0 font-first text-default text-5xl font-semibold absolute m-auto md:text-3xl md:-mt-1 -mt-10">{t("PenthouseDaire")}</h1>
            </div>
            <div className="w-full flex flex-col relative before:w-[1px] before:absolute before:left-0 before:right-0 before:top-0 before:bottom-0 before:mx-auto before:bg-default md:before:hidden">
                <Link to="./bomonti-penthouse" className="w-full flex items-center group odd md:flex-col md:w-11/12 md:mx-auto md:py-5">
                    <img src={bomontiPenthouse} alt="Bomonti Penthouse" className="w-4/12 md:w-full" />
                    <div className="flex items-center justify-end w-2/12 pr-5 border-r-[5px] ml-[3px] md:ml-0 border-default py-5 md:py-3 mr-5 md:mr-0 md:pr-0 md:w-full md:border-r-0 md:border-b-2 md:justify-center">
                        <div className="flex flex-col items-end ml-10 md:ml-0 md:items-center">
                            <div className="font-first text-default text-lg font-light md:text-base">{t("IstTurkey")}</div>
                            <h1 className="font-first font-normal text-default text-3xl my-3 text-right md:text-center md:text-2xl md:my-1">BOMONTİ<br />PENTHOUSE</h1>
                            <div className="font-first text-default font-light text-lg md:text-base">350 m²</div>
                        </div>
                    </div>
                    <div className="font-first text-default opacity-30 text-[150px] font-extralight md:text-4xl md:mt-2">2021</div>
                    <img src={arrowThin} alt="projects arrow" className="ml-28 transition-all duration-300 group-hover:ml-40 md:mt-2 md:ml-0 md:scale-75" />
                </Link>
                <Link to="./florya-penthouse" className="w-full flex items-center justify-end group even bg-white md:flex-col-reverse md:py-5">
                    <img src={arrowThin} alt="projects arrow" className="mr-28 rotate-180 transition-all duration-300 group-hover:mr-40 md:rotate-0 md:mr-0 md:mt-2 md:group-hover:ml-40 md:group-hover:mr-0 md:scale-75" />
                    <div className="font-first text-default opacity-30 text-[150px] font-extralight md:text-4xl md:mt-2">2020</div>
                    <div className="flex items-center justify-start w-2/12 pl-5 border-l-[5px] mr-[3px] md:mr-0 border-default py-5 ml-5 md:ml-0 md:pl-0 md:py-3 md:border-l-0 md:border-b-2 md:w-11/12 md:justify-center">
                        <div className="flex flex-col items-start mr-10 md:mr-0 md:items-center">
                            <div className="font-first text-default text-lg font-light md:text-base">{t("IstTurkey")}</div>
                            <h1 className="font-first font-normal text-default text-3xl my-3 text-left md:my-1 md:text-center md:text-2xl">FLORYA<br />PENTHOUSE</h1>
                            <div className="font-first text-default font-light text-lg md:text-base">250 m²</div>
                        </div>
                    </div>
                    <img src={floryaPenthouse} alt="Florya Penthouse" className="w-4/12 md:w-11/12" />
                </Link>
                <Link to="./kosuyolu-penthouse-1" className="w-full flex items-center group odd md:flex-col md:w-11/12 md:mx-auto md:py-5">
                    <img src={kosuyoluPenthouse} alt="Koşuyolu Penthouse" className="w-4/12 md:w-full" />
                    <div className="flex items-center justify-end w-2/12 pr-5 border-r-[5px] ml-[3px] md:ml-0 border-default py-5 md:py-3 mr-5 md:mr-0 md:pr-0 md:w-full md:border-r-0 md:border-b-2 md:justify-center">
                        <div className="flex flex-col items-end ml-10 md:ml-0 md:items-center">
                            <div className="font-first text-default text-lg font-light md:text-base">{t("IstTurkey")}</div>
                            <h1 className="font-first font-normal text-default text-3xl my-3 text-right md:text-center md:text-2xl md:my-1">KOŞUYOLU<br />PENTHOUSE 1</h1>
                            <div className="font-first text-default font-light text-lg md:text-base">400 m²</div>
                        </div>
                    </div>
                    <div className="font-first text-default opacity-30 text-[150px] font-extralight md:text-4xl md:mt-2">2020</div>
                    <img src={arrowThin} alt="projects arrow" className="ml-28 transition-all duration-300 group-hover:ml-40 md:mt-2 md:ml-0 md:scale-75" />
                </Link>
                <Link to="./kosuyolu-penthouse-2" className="w-full flex items-center justify-end group even bg-white md:flex-col-reverse md:py-5">
                    <img src={arrowThin} alt="projects arrow" className="mr-28 rotate-180 transition-all duration-300 group-hover:mr-40 md:rotate-0 md:mr-0 md:mt-2 md:group-hover:ml-40 md:group-hover:mr-0 md:scale-75" />
                    <div className="font-first text-default opacity-30 text-[150px] font-extralight md:text-4xl md:mt-2">2020</div>
                    <div className="flex items-center justify-start w-2/12 pl-5 border-l-[5px] mr-[3px] md:mr-0 border-default py-5 ml-5 md:ml-0 md:pl-0 md:py-3 md:border-l-0 md:border-b-2 md:w-11/12 md:justify-center">
                        <div className="flex flex-col items-start mr-10 md:mr-0 md:items-center">
                            <div className="font-first text-default text-lg font-light md:text-base">{t("IstTurkey")}</div>
                            <h1 className="font-first font-normal text-default text-3xl my-3 text-left md:my-1 md:text-center md:text-2xl">KOŞUYOLU<br />PENTHOUSE 2</h1>
                            <div className="font-first text-default font-light text-lg md:text-base">400 m²</div>
                        </div>
                    </div>
                    <img src={kosuyoluPenthouse2} alt="Koşuyolu Penthouse 2" className="w-4/12 md:w-11/12" />
                </Link>
                <Link to="./atasehir-daire" className="w-full flex items-center group odd md:flex-col md:w-11/12 md:mx-auto md:py-5">
                    <img src={atasehirDaire} alt="Ataşehir Daire" className="w-4/12 md:w-full" />
                    <div className="flex items-center justify-end w-2/12 pr-5 border-r-[5px] ml-[3px] md:ml-0 border-default py-5 md:py-3 mr-5 md:mr-0 md:pr-0 md:w-full md:border-r-0 md:border-b-2 md:justify-center">
                        <div className="flex flex-col items-end ml-10 md:ml-0 md:items-center">
                            <div className="font-first text-default text-lg font-light md:text-base">{t("IstTurkey")}</div>
                            <h1 className="font-first font-normal text-default text-3xl my-3 text-right md:text-center md:text-2xl md:my-1">ATAŞEHİR<br />{t("Daire")}</h1>
                            <div className="font-first text-default font-light text-lg md:text-base">150 m²</div>
                        </div>
                    </div>
                    <div className="font-first text-default opacity-30 text-[150px] font-extralight md:text-4xl md:mt-2">2018</div>
                    <img src={arrowThin} alt="projects arrow" className="ml-28 transition-all duration-300 group-hover:ml-40 md:mt-2 md:ml-0 md:scale-75" />
                </Link>
                <Link to="./burhaniye-konaklari" className="w-full flex items-center justify-end group even bg-white md:flex-col-reverse md:py-5">
                    <img src={arrowThin} alt="projects arrow" className="mr-28 rotate-180 transition-all duration-300 group-hover:mr-40 md:rotate-0 md:mr-0 md:mt-2 md:group-hover:ml-40 md:group-hover:mr-0 md:scale-75" />
                    <div className="font-first text-default opacity-30 text-[150px] font-extralight md:text-4xl md:mt-2">2020</div>
                    <div className="flex items-center justify-start w-2/12 pl-5 border-l-[5px] mr-[3px] md:mr-0 border-default py-5 ml-5 md:ml-0 md:pl-0 md:py-3 md:border-l-0 md:border-b-2 md:w-11/12 md:justify-center">
                        <div className="flex flex-col items-start mr-10 md:mr-0 md:items-center">
                            <div className="font-first text-default text-lg font-light md:text-base">{t("IstTurkey")}</div>
                            <h1 className="font-first font-normal text-default text-3xl my-3 text-left md:my-1 md:text-center md:text-2xl">BURHANİYE<br />{t("Konaklari")}</h1>
                            <div className="font-first text-default font-light text-lg md:text-base">220 m²</div>
                        </div>
                    </div>
                    <img src={burhaniyeKonaklari} alt="Burhaniye Konakları" className="w-4/12 md:w-11/12" />
                </Link>
            </div>
            <Link to="../" className="font-first text-default text-xl bg-white pr-3 flex items-center w-fit mt-40 ml-16 hover:ml-10 transition-all duration-300 rounded-3xl md:mt-14 md:mx-auto">
                <img src={backToProjects} alt="Projelere Dön" className="mr-3" />
                {t("backProjects")}
            </Link>
        </Layout>
    )
}

export default Penthouse;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;